.about-section {
  background: linear-gradient(to right, #ffffff, #f4f4f4); /* Dégradé léger de blanc à gris clair */
}

.about-content {
  display: flex;
  align-items: center; /* Centrage vertical du contenu */
  text-align: justify; /* Justifier le texte pour une lecture plus aisée */
}

.about-text p {
  line-height: 1.8; /* Augmentation de l'interligne pour une meilleure lisibilité */
}

.button.is-info {
  margin-top: 2rem; /* Espacement au-dessus du bouton */
}


.about-image img {
  max-height: 66.67%; /* Réduit la hauteur de l'image d'un tiers */
  object-fit: cover; /* Assure que l'image couvre bien la zone définie sans perdre son aspect ratio */
}

@media screen and (max-width: 900px) {
  .about-content .column {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .about-image img {
    max-width: 100%;
    height: auto;
  }
}
