/* Styles par défaut pour le mode clair */
body {
  background-color: white;
  color: black;
}

/* Styles pour le mode sombre */
.dark body {
  background-color: #333;
  color: white;
}

/* Vous pouvez étendre cela à d'autres éléments */
.dark .navbar {
  background-color: #222;
}

.dark .text {
  color: #ddd;
}