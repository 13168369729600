.card {
  transition: box-shadow 0.3s ease;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.card:hover {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}


.realisations-container {
  padding-top: 100px; /* Ajustez en fonction de la hauteur de votre Navbar */
}

.realisation-detail-container {
  padding-top: 100px; /* Ajustez en fonction de la hauteur de votre Navbar */
}

.title, .subtitle {
  color: #333; /* Couleur plus foncée pour plus de contraste */
}

.content p {
  line-height: 1.6; /* Améliore la lisibilité */
}

.images figure.image {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 9/16 = 0.5625 */
}

.images figure.image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnails {
  display: flex;
  flex-wrap: wrap; /* Permet aux thumbnails de passer à la ligne si nécessaire */
  justify-content: center; /* Centrer les thumbnails horizontalement */
  align-items: center; /* Centrer les thumbnails verticalement si différentes hauteurs */
  background-color: #f5f5f5; /* Fond gris clair */
  padding: 20px 0; /* Espacement vertical à l'intérieur du container */
  margin: 20px 0; /* Espacement extérieur autour du container */
  width: 100%;
}

.thumbnails img {
  cursor: pointer;
  margin: 10px; /* Plus d'espace autour de chaque thumbnail */
  width: 100px; /* Taille des thumbnails */
  height: auto;
  transition: transform 0.2s; /* Animation pour le survol */
  object-fit: cover;
}

.thumbnails img:hover {
  opacity: 0.7;
  transform: scale(1.05); /* Légère augmentation de taille au survol */
}
